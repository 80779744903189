import React from "react";
import { StaticImage } from "gatsby-plugin-image";
import data from "../../roadmap/Q2-2023-details";
import Page from "../../components/Page";
import Section from "../../components/Section";
import InlineNav from "../../components/InlineNav";
import FeaturesAccordion from "../../components/FeaturesAccordion";
import getPrevNextRoadmaps from "../../utils/getPrevNextRoadmaps";
import WithSideNav from "../../components/WithSideNav";

const roadmapId = "Q2-2023";

function RoadmapPage({ location }) {
  const sideNavProps = [
    {
      title: "Collaborate",
      link: "/collaborate"
    },
    {
      title: "News & Updates",
      link: "/news"
    },
    {
      title: "Roadmap",
      link: "/roadmap"
    }
  ];

  const { prev, next } = getPrevNextRoadmaps(roadmapId);
  return (
    <Page>
      <Section>
        <WithSideNav sideNavProps={sideNavProps} title="Roadmap">
          <p className="mt-8 mb-8">
            We are excited to share our plans with the community! Below you may
            find an outline of our ongoing projects and major components
            scheduled to be included in upcoming feature versions. We would love
            to hear your feedback on our roadmap and welcome you to join the
            conversation on our
            <span className="text-blue-100">
              <a
                href="https://community.ohif.org/"
                target="_blank"
                rel="noreferrer"
              >
                {" community forum."}
              </a>
            </span>
          </p>
          <div className="flex gap-2 text-white">
            <InlineNav
              prev={prev}
              next={next}
              current={"Q2 2023 Update"}
              base={"roadmap"}
            />
          </div>
          <div className="mb-8 mt-2">
            <StaticImage
              src={"../../images/Roadmap-2023-Q2-to-2024-Q1.png"}
              alt="roadmap"
            />
          </div>
          <div className="mb-20 mt-15 text-white">
            <h3 className="phone-size:h3-heading-ps lg:h3-heading-lg">
              Details
            </h3>
            <p className="text-white mb-3">
              We understand the importance of transparency and want to provide
              you with a glimpse into our plans for the future. We are planning
              exciting advancements in visualization and segmentation, enhanced
              support for functional imaging, simplifying installation and
              deployment, building a Quality Management System (QMS), and more.
              It is important to note that as we progress, our priorities may
              evolve based on the ever-changing needs of our user community.
              Rest assured, our aim is to continually enhance your experience
              and optimize your image analysis workflows.
            </p>
            <FeaturesAccordion
              data={data}
              location={location}
              basePath={`roadmap/${roadmapId}`}
            />
          </div>
        </WithSideNav>
      </Section>
    </Page>
  );
}

export default RoadmapPage;
